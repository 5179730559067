// External libs
import React, { useRef } from 'react';
import * as d3 from 'd3';
import { FaEye, FaEyeSlash, FaFileImport } from 'react-icons/fa';
import FileSaver from 'file-saver';
import { Box, Button, Checkbox, Input, MenuItem } from '@chakra-ui/react';
import { AssignLinkValueToCorrectVar, DefaultLinkStyle, DefaultNode, LinkColor, NodeColor, OSTooltip, ReturnValueLink, } from './import/OpenSankey';
// OpenSankey js-code
import { pre_process_export_svg, post_process_export_svg } from 'open-sankey/dist/topmenus/SankeyMenuTop';
// Local imports
// import { OSPData,OSPNode, } from './types'
import SankeyListIcons from './icons/lib_of_icons.json';
export const DefaultOSPStyleLink = () => {
    const style = DefaultLinkStyle();
    style.gradient = false;
    return style;
};
// export const DragLegendOSP: DragLegendOSPFType = (
//   data: OSPData,
//   multi_selected_label: { current: OSPLabel[] },
//   ComponentUpdater,
//   resizeCanvas,
//   node_function,
//   link_function,
//   applicationData
// ) => d3
//   .drag<SVGGElement, unknown>()
//   .subject(Object).on('drag', function (event) {
//     if (d3.select('.opensankey #svg').nodes().length > 0) {
//       DragLegendGElementOSTyped(data, event)
//       if (data.legend_position[0] === 0 || data.legend_position[1] === 0) {
//         OpposingDragElementsPlus(
//           [({ x: data.legend_position[0], y: data.legend_position[1] } as OSPNode)],
//           event,
//           ({} as OSPNode), applicationData, { current: [] },
//           multi_selected_label)
//       }
//     }
//   })
//   .on('end', () => {
//     ComponentUpdater.updateComponentMenuConfigLayout.current()
//     node_function.RedrawNodes(Object.values(applicationData.display_nodes))
//     link_function.RedrawLinks(Object.values(applicationData.display_links))
//     resizeCanvas()
//   })
export const ImportImageAsSvgBg = ({ t, data, set_data, has_open_sankey_plus, }) => {
    const _load_image = useRef(null);
    const content_image = React.createElement(React.Fragment, null,
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: data.show_background_image, isDisabled: !has_open_sankey_plus, icon: data.show_background_image ? React.createElement(FaEye, null) : React.createElement(FaEyeSlash, null), onChange: (evt) => {
                    data.show_background_image = evt.target.checked;
                    set_data(Object.assign({}, data));
                } }, t('MEP.show_image')),
            React.createElement(OSTooltip, { label: !has_open_sankey_plus ? t('Menu.sankeyOSPDisabled') : '' },
                React.createElement(Box, null,
                    React.createElement(Button, { variant: 'menuconfigpanel_option_button', isDisabled: !data.show_background_image || !has_open_sankey_plus, onClick: () => {
                            if (_load_image.current) {
                                _load_image.current.name = '';
                                _load_image.current.click();
                            }
                        } },
                        React.createElement(FaFileImport, null)),
                    React.createElement(Input, { ref: _load_image, style: { display: 'none' }, accept: 'image/*', type: "file", value: '', disabled: !has_open_sankey_plus, onChange: (evt) => {
                            const files = evt.target.files;
                            const reader = new FileReader();
                            reader.onload = (() => {
                                return (e) => {
                                    const resultat = e.target.result;
                                    const res = resultat === null || resultat === void 0 ? void 0 : resultat.toString().replaceAll('=', '');
                                    data.background_image = res;
                                    set_data(Object.assign({}, data));
                                };
                            })();
                            reader.readAsDataURL(files[0]);
                        } })))));
    return content_image;
};
export const SetSvgBg = (data) => {
    d3.select('#svg')
        .filter(() => !data.show_background_image || data.background_image === undefined || data.background_image === '')
        .style('background-image', null)
        .style('background-size', 'contain')
        .style('background-repeat', 'no-repeat');
    d3.select('#svg')
        .filter(() => data.show_background_image && data.background_image !== undefined && data.background_image !== '')
        .style('background-image', 'url(' + data.background_image + ')')
        .style('background-size', 'contain')
        .style('background-repeat', 'no-repeat');
};
export const IsAllZdtAttrSameValue = (data, m_s_zdt, k) => {
    if (m_s_zdt.length === 0) {
        return [null, null];
    }
    const first_value = m_s_zdt[0][k];
    let all_same = true;
    m_s_zdt.forEach(l => {
        all_same = l[k] !== first_value ? false : all_same;
    });
    return (all_same ? [first_value, false] : [0, true]);
};
// export const PlusIsAllNodeNotLocalAttrSameValue=(data:OSPData,m_s_n:OSPNode[],k_list:(keyof OSPNode)[])=>{
// return IsAllNodeNotLocalAttrSameValue(data,m_s_n,k_list)
// }
export const OSPIsAllNodeNotLocalAttrSameValue = (data, m_s_n, k_list) => {
    // store_value : variable that contain an array forEach key we are looking for
    // Each array contain in first position the value of the selected nodes attribute
    // In second position it contain a boolean that return true if all selected nodes have the same value for the key
    const store_value = {};
    if (m_s_n.length > 0) {
        // For each selected nodes
        m_s_n.forEach((node, i) => {
            // For each attributes we want to check
            k_list.forEach(k => {
                // Get the value of the node attribute(k)
                const val = node[k];
                // Store first value of each node attribute
                if (i === 0) {
                    store_value[k] = [val, false];
                }
                else {
                    // Check if other nodes selected have the same value, if not we set the 2nd value of the array at true
                    store_value[k][1] = val !== store_value[k][0] ? true : store_value[k][1];
                }
            });
        });
    }
    else {
        k_list.forEach(k => {
            store_value[k] = [false, false];
        });
    }
    return store_value;
};
export const OSPReturnValueLink = (data, l, k) => {
    return ReturnValueLink(data, l, k);
};
export const OSPAssignLinkValueToCorrectVar = (l, k, v, menu_for_style) => {
    return AssignLinkValueToCorrectVar(l, k, v, menu_for_style);
};
/**
 * Function to color node sabot (only for node with arrow shape)
 * override OS function by adding behavior of when the link is gradient
 * nevertheless if the link take the color of tag associated then it use the color of the tag instead of the gradient
 * @param {OSPLink} l link sabot to color
 * @param {OSPData} data sankey data
 * @param {GetLinkValueFuncType} GetLinkValue
 * @return {string} color (hex) to use
 */
export const OSPLinkSabotColor = (l, data, GetLinkValue) => {
    let chooseNodeColor = false;
    if (data.linksColorMap === 'no_colormap') {
        chooseNodeColor = OSPReturnValueLink(data, l, 'gradient') === true;
    }
    return chooseNodeColor ? NodeColor(data.nodes[l.idSource], data) : LinkColor(l, data, GetLinkValue);
};
// Function used before exporting a sankey to svg format
// It add used attribute from css file in the html tag attribute 'style', because otherwise the foreignObject element doesn't have proper css
const addStyleInlineSVG = () => {
    d3.selectAll('#svg foreignObject *:not(br)').nodes().forEach(el => {
        const elements_for_style = getComputedStyle(el);
        Object.values(elements_for_style).forEach(ks => {
            // Get css properties of the element in the navigator
            const val = elements_for_style.getPropertyValue(ks);
            if (val !== undefined && val !== '' && !ks.includes('webkit')) {
                // Add the css propertie in the attribute style so it is used in the svg file
                d3.select(el).style(ks, val);
            }
        });
    });
};
export const clickSaveSVG = () => {
    const svg = pre_process_export_svg();
    addStyleInlineSVG();
    const html = svg.attr('title', 'test2')
        .attr('version', 1.1)
        .attr('xmlns', 'http://www.w3.org/2000/svg')
        .node().parentNode.innerHTML;
    const blob = new Blob([html], { type: 'image/svg+xml' });
    const form_data = new FormData();
    form_data.append('html', blob);
    post_process_export_svg();
    const path = window.location.href;
    let url = path + '/opensankey/sankey/save_svg';
    const fetchData = {
        method: 'POST',
        body: form_data
    };
    const showFile = (blob) => {
        const newBlob = new Blob([blob], { type: 'application/svg' });
        FileSaver.saveAs(newBlob, 'sankey_diagram.svg');
    };
    const cleanFile = () => {
        const fetchData = {
            method: 'POST'
        };
        url = path + '/opensankey/sankey/clean_svg';
        fetch(url, fetchData);
    };
    fetch(url, fetchData).then(r => r.blob())
        .then(showFile).then(cleanFile);
};
export const OSPItemExport = () => {
    return React.createElement(MenuItem, { onClick: clickSaveSVG }, "SVG");
};
export const OSPDefaultNode = (data) => {
    const os_def_node = DefaultNode(data);
    const def_plus_data_var = {
        iconName: '',
        iconColor: '',
        iconVisible: false,
        iconColorSustainable: false,
        has_FO: false,
        is_FO_raw: false,
        FO_content: '',
        is_image: false,
        image_src: '',
        hyperlink: ''
    };
    const osp_def_node = Object.assign(Object.assign({}, os_def_node), def_plus_data_var);
    return osp_def_node;
};
export const generate_data_example_icons = (get_default_data) => {
    const data_sankey = get_default_data();
    data_sankey.icon_catalog = {};
    data_sankey.style_node['default'].node_height = 100;
    data_sankey.style_node['default'].node_width = 100;
    data_sankey.style_node['default'].shape_visible = false;
    Object.keys(SankeyListIcons).forEach((cki, nki) => {
        const ki = cki;
        Object.entries(SankeyListIcons[ki]).forEach((i, n) => {
            data_sankey.icon_catalog[i[0]] = i[1];
            const node = OSPDefaultNode(data_sankey);
            node.idNode = 'key_' + i[0];
            node.name = i[0];
            node.iconName = i[0];
            node.iconVisible = true;
            node.iconColor = 'black';
            node.x = (n * 150) + 50;
            node.y = (nki * 200) + 100;
            data_sankey.nodes[node.idNode] = node;
        });
    });
    return data_sankey;
};
