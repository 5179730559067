// External libs
import React, { useEffect, useState, useRef } from 'react';
import * as d3 from 'd3';
import FileSaver from 'file-saver';
import { ProcessExample, convert_data } from '../import/OpenSankey';
// OpenSankey js-code
import { list_palette_color, AssignNodeLocalAttribute } from 'open-sankey/dist/configmenus/SankeyUtils';
import { Alert, AlertIcon, Box, Button, Checkbox, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader } from '@chakra-ui/react';
export const SupplyUseModelisationProd = ({ ref_setter_show_reconciliation, action_type, applicationData, DefaultSankeyData, updateLayout, postProcessLoadExcel, launch, applicationContext }) => {
    const { data, set_data } = applicationData;
    const { t } = applicationContext;
    const [result, setResult] = useState('');
    const [processing, setProcessing] = useState(false);
    const [failure, setFailure] = useState(false);
    const [not_started, setNotStarted] = useState(true);
    const [value, setValue] = useState([1, 2]);
    const [regions, setRegions] = useState(false);
    const [uncertainty, setUncertainty] = useState(false);
    const [nb_realizations, setNbRealizations] = useState(100);
    const [input_file_name, setInputFileName] = useState('sankey.xlsx');
    const [layout_file, set_layout_file] = useState(undefined);
    const [input_file, set_input_file] = useState(undefined);
    const _load_excel = useRef(null);
    const [show_reconciliation, set_show_reconciliation] = useState(false);
    ref_setter_show_reconciliation.current = set_show_reconciliation;
    let upper_level_file_ = useRef(null);
    const setNbRealisations = (e) => {
        setNbRealizations(e.target.value);
    };
    const uncertaintyChange = (e) => {
        setUncertainty(e.target.checked);
    };
    const handleChange = (evt) => {
        const value_selected = +evt.target.value;
        if (value.includes(value_selected)) {
            value.splice(value.indexOf(value_selected), 1);
        }
        else {
            value.push(+evt.target.value);
        }
        setValue([...value]);
    };
    const regionChange = (evt) => {
        setRegions(evt.target.checked);
    };
    const setInputFile = (evt) => {
        set_input_file(evt.target.files[0]);
    };
    const setLayoutFile = (evt) => {
        set_layout_file(evt.target.files[0]);
    };
    const setRegionFile = (evt) => {
        upper_level_file_ = evt.target.files[0];
    };
    // Define cleaning temporary dir function
    const CleanServer = () => {
        const path = window.location.href;
        const url_optimize_prod_clean = path + 'sankeytools/optimize_prod_clean';
        const fetchData = {
            method: 'POST',
            body: ''
        };
        fetch(url_optimize_prod_clean, fetchData)
            .then(function (response) {
            if (!response.ok) {
                setResult(result + '\n ERROR: optimize_prod_clean a retourné une erreur qui n\'est pas en format json');
                setFailure(true);
            }
            else {
                setProcessing(false);
                setFailure(failure);
                setNotStarted(false);
            }
        })
            .catch(function () {
            setResult(result + '\n ERROR: optimize_prod_clean: erreur fatale.');
            setFailure(true);
        });
        return;
    };
    const GetRandomInt = (max) => {
        return Math.floor(Math.random() * max);
    };
    // Function called to download the excel file from the reconciliation
    const RetrievesResults = () => {
        // Define download excel file function
        function DownloadFile(blob) {
            const root_input_file_name = input_file_name.split('.')[0];
            let suffix = '_reconciled.xlsx';
            if (action_type.current === 'check_excel') {
                suffix = '_corrected.xlsx';
            }
            else if (action_type.current === 'create_empty_ter') {
                suffix = '_ter_created.xlsx';
            }
            const output_file_name = root_input_file_name + suffix;
            const newBlob = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(newBlob, output_file_name);
            return newBlob;
        }
        // Define Display Sankey function
        // Fetching results
        const path = window.location.href;
        const url_optimize_retrieves_results = path + 'sankeytools/optimize_retrieves_results';
        const form_data = new FormData();
        const fetchData = {
            method: 'POST',
            body: form_data
        };
        fetch(url_optimize_retrieves_results, fetchData).then(function (response) {
            if (response.ok) {
                if (action_type.current === 'check_excel') {
                    CleanServer();
                }
                else {
                    response
                        .blob()
                        .then(DownloadFile);
                }
            }
            else {
                response
                    .json()
                    .then(data => {
                    setResult(data.output);
                    setFailure(true);
                })
                    .then(CleanServer)
                    .catch(() => {
                    setResult(result + '\n ERROR: optimize_retrieves_result a retourné une erreur qui n\'est pas en format json');
                    setFailure(true);
                });
                action_type.current = '';
                setNotStarted(true);
            }
        });
        return;
    };
    // Function called to display the excel file from the reconciliation,
    // it open the file like a normal excel file
    const DisplayResults = () => {
        // Define Display Sankey function
        function DisplaySankey(value) {
            if (action_type.current == 'optim') {
                launch(value.name);
                const root = window.location.href;
                const url = root + '/sankeytools/' + 'sankey/upload_excel';
                const form_data = new FormData();
                form_data.append('file', value);
                const fetchData = {
                    method: 'POST',
                    body: form_data
                };
                fetch(url, fetchData);
                return;
            }
            else {
                // Get Layout from file if asked
                if (layout_file !== undefined) {
                    const reader = new FileReader();
                    // Reader behavior on layout reading
                    reader.onload = (() => {
                        return ((e) => {
                            let result = e.target.result;
                            if (result) {
                                result = String(result);
                                const layout = JSON.parse(result);
                                data.layout = layout;
                            }
                        });
                    });
                    // Read layout
                    reader.readAsText(layout_file);
                }
                // Update displayed Sankey
                const root = window.location.href;
                const url_optimize_display_results = root + 'sankeytools/optimize_display_results';
                const form_data = new FormData();
                const fetchData = {
                    method: 'POST',
                    body: form_data
                };
                fetch(url_optimize_display_results, fetchData)
                    .then(response => {
                    response
                        .text()
                        .then(GetAndApplySankeyJson);
                });
            }
        }
        // Define get results function
        function GetAndApplySankeyJson(new_sankey_json) {
            var _a;
            try {
                // Extract data as JSON
                const new_sankey_data = JSON.parse(new_sankey_json);
                // Check if we got any error
                const error = new_sankey_data['error'];
                if (error && error.length != 0) {
                    alert(error);
                    return;
                }
                // Reconcilliation from current Sankey -> Apply current layout
                if (action_type.current === 'optim_sankey') {
                    const layout = JSON.parse(JSON.stringify(data));
                    new_sankey_data.layout = layout;
                }
                const new_data = Object.assign(DefaultSankeyData(), new_sankey_data);
                applicationData.data = new_data;
                ProcessExample(applicationData, updateLayout, convert_data, postProcessLoadExcel, DefaultSankeyData);
                const default_nstyle = DefaultSankeyData().style_node['default'];
                const default_lstyle = DefaultSankeyData().style_link['default'];
                new_data.style_node['default'] = default_nstyle;
                new_data.style_link['default'] = default_lstyle;
                delete new_data.layout;
                if (Object.values(new_data.nodeTags).filter(tagg => tagg.show_legend).length > 0) {
                    new_data.colorMap = Object.entries(new_data.nodeTags).filter(tagg => tagg[1].show_legend)[0][0];
                    Object.values(new_data.nodes).forEach(el => {
                        el.colorParameter = 'groupTag';
                        el.colorTag = new_data.colorMap;
                    });
                }
                if (Object.keys(new_data.nodeTags).filter(t => new_data.nodeTags[t].show_legend).length == 0 &&
                    Object.keys(new_data.fluxTags).filter(tag => tag === 'flux_type').length == 0 &&
                    Object.values(new_data.nodes).filter(n => n.local && n.local.color).length == 0 &&
                    Object.values(new_data.links).filter(l => l.local && l.local.color).length == 0) {
                    const color_selected = list_palette_color[GetRandomInt(list_palette_color.length)];
                    const n_keys = Object.keys(new_data.nodes);
                    const size_color = n_keys.length;
                    for (const i in d3.range(size_color)) {
                        AssignNodeLocalAttribute(new_data.nodes[n_keys[i]], 'color', (_a = d3.color(color_selected(+i / size_color))) === null || _a === void 0 ? void 0 : _a.formatHex());
                    }
                }
                set_data(Object.assign({}, new_data));
            }
            catch (err) {
                alert('Could not display optimized Sankey : ' + err);
            }
            return;
        }
        // Fetching results
        const path = window.location.href;
        const url_optimize_retrieves_results = path + 'sankeytools/optimize_retrieves_results';
        const form_data = new FormData();
        const fetchData = {
            method: 'POST',
            body: form_data
        };
        fetch(url_optimize_retrieves_results, fetchData).then(function (response) {
            if (response.ok) {
                if (action_type.current === 'check_excel') {
                    CleanServer();
                }
                else {
                    response
                        .blob()
                        .then(DisplaySankey);
                }
            }
            else {
                response
                    .json()
                    .then(data => {
                    setResult(data.output);
                    setFailure(true);
                })
                    .then(CleanServer)
                    .catch(() => {
                    setResult(result + '\n ERROR: optimize_retrieves_result a retourné une erreur qui n\'est pas en format json');
                    setFailure(true);
                });
                action_type.current = '';
                setNotStarted(true);
            }
        });
        return;
    };
    const reset = () => {
        setProcessing(false);
        setFailure(false);
        setNotStarted(true);
        setResult('');
    };
    const launchReconciliation = () => {
        if (!input_file && action_type.current !== 'optim_sankey') {
            return;
        }
        // Optimisation params
        const data_server = new FormData();
        data_server.append('input_file', input_file);
        data_server.append('optim_sankey', String(action_type.current === 'optim_sankey'));
        data_server.append('create_empty_ter', String(action_type.current === 'create_empty_ter'));
        data_server.append('check_excel', String(action_type.current === 'check_excel'));
        data_server.append('uncertainty_analysis', String(uncertainty));
        data_server.append('nb_realizations', String(nb_realizations));
        data_server.append('upper_level_file', upper_level_file_.name);
        if (action_type.current === 'optim_sankey') {
            const new_sankey_data = JSON.parse(JSON.stringify(data));
            new_sankey_data.icon_catalog = {};
            data_server.append('sankey_data', JSON.stringify(new_sankey_data));
        }
        // POST request for optimisation
        const path = window.location.href;
        const url = path + 'sankeytools/optimize_prod_launch';
        const fetchData = {
            method: 'POST',
            body: data_server
        };
        fetch(url, fetchData);
        // Set processing indicators
        setProcessing(true);
        setFailure(true);
        setNotStarted(false);
        setResult('');
        if (input_file) {
            setInputFileName(input_file.name);
        }
    };
    const FinishReconciliation = () => {
        // Retrieve results
        if (!failure) {
            RetrievesResults();
        }
        else {
            CleanServer();
        }
    };
    const infos = (result !== undefined) ? result.split('\n') : [];
    let title = 'Réconciliation des données';
    if (action_type.current === 'check_excel') {
        title = 'Vérification du fichier d\'entrée';
    }
    else if (action_type.current === 'create_empty_ter') {
        title = 'Création de la table Ressources Emplois';
    }
    let success_status = t('reconciliation.success_status_optim');
    if (action_type.current === 'check_excel') {
        success_status = t('reconciliation.success_status_check_excel');
    }
    else if (action_type.current === 'create_empty_ter') {
        success_status = t('reconciliation.success_status_create_ter');
    }
    let failure_status = t('reconciliation.fail_status_optim');
    if (action_type.current === 'check_excel') {
        failure_status = t('reconciliation.fail_status_check_excel');
    }
    else if (action_type.current === 'create_empty_ter') {
        failure_status = t('reconciliation.fail_status_create_ter');
    }
    useEffect(() => {
        if (action_type.current === '' && result !== '') {
            reset();
        }
    });
    if (show_reconciliation) {
        if (not_started && action_type.current === 'optim_sankey') {
            launchReconciliation();
        }
        else if (action_type.current === '') {
            setResult('');
        }
    }
    return (React.createElement(Modal, { size: "lg", isOpen: show_reconciliation, onClose: () => {
            CleanServer();
            set_show_reconciliation(false);
            action_type.current = '';
        } },
        React.createElement(ModalContent, { maxWidth: 'inherit' },
            React.createElement(ModalHeader, null, t('reconciliation.reconciliation')),
            React.createElement(ModalCloseButton, null),
            React.createElement(ModalBody, null,
                action_type.current !== 'optim_sankey' ? (React.createElement(React.Fragment, null,
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_1' }, t('reconciliation.input_parameter')),
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                        React.createElement(Box, { layerStyle: 'menuconfigpanel_suboption_name' }, t('reconciliation.input_excel')),
                        React.createElement(Input, { size: 'xs', type: "file", accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel", ref: _load_excel, onChange: setInputFile })),
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                        React.createElement(Box, { layerStyle: 'menuconfigpanel_suboption_name' }, t('reconciliation.input_layout')),
                        React.createElement(Input, { size: 'xs', type: "file", onChange: setLayoutFile })))) : (React.createElement(React.Fragment, null)),
                action_type.current === 'optim' ? (React.createElement(Checkbox, { isChecked: regions, variant: 'menuconfigpanel_option_checkbox', onChange: regionChange }, t('reconciliation.check_scale_geo'))) : (React.createElement("div", null)),
                regions && action_type.current === 'optim' ? (React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                    React.createElement(Box, { layerStyle: 'menuconfigpanel_suboption_name' }, t('reconciliation.input_scale_geo')),
                    React.createElement(Input, { size: 'xs', type: "file", name: "", onChange: setRegionFile }))) : (React.createElement("div", null)),
                action_type.current === 'optim' ? (React.createElement(Checkbox, { isChecked: uncertainty, variant: 'menuconfigpanel_option_checkbox', onChange: uncertaintyChange }, t('reconciliation.check_analyse_uncert'))) : (React.createElement("div", null)),
                uncertainty && action_type.current === 'optim' ? (React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                    React.createElement(Box, { layerStyle: 'menuconfigpanel_suboption_name' }, t('reconciliation.input_analyse_uncert')),
                    React.createElement(Input, { size: 'xs', isDisabled: !uncertainty, value: nb_realizations, type: "text", onChange: setNbRealisations }))) : (React.createElement("div", null)),
                React.createElement("hr", null),
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_1' }, title),
                React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' }, input_file || action_type.current === 'optim_sankey' ?
                    (not_started ? (React.createElement(Button, { variant: "menuconfigpanel_option_button", onClick: launchReconciliation }, t('reconciliation.launch'))) :
                        processing ? (React.createElement(Button, { variant: "menuconfigpanel_option_button_secondary" },
                            React.createElement("span", { className: "glyphicon glyphicon-refresh glyphicon-refresh-animate" }),
                            t('reconciliation.processing'))) : (failure ?
                            React.createElement(Button, { variant: "menuconfigpanel_del_button", onClick: reset }, failure_status) :
                            React.createElement(Box, { as: 'span', layerStyle: 'options_3cols' },
                                React.createElement(Button, { variant: "menuconfigpanel_option_button", onClick: DisplayResults }, t('reconciliation.open_file')),
                                React.createElement(Button, { variant: "menuconfigpanel_option_button_secondary", onClick: FinishReconciliation }, success_status),
                                React.createElement(Button, { variant: "menuconfigpanel_del_button", onClick: reset }, t('reconciliation.reset'))))) : (React.createElement(Button, { variant: "primary", disabled: true, onClick: launchReconciliation }, t('reconciliation.launch')))),
                input_file === undefined && action_type.current !== 'optim_sankey' ? React.createElement(Alert, { status: 'warning' },
                    React.createElement(AlertIcon, null),
                    t('reconciliation.waiting_file')) : React.createElement(React.Fragment, null),
                !not_started && !processing && !failure ? (React.createElement(Alert, { status: 'success' },
                    React.createElement(AlertIcon, null),
                    t('reconciliation.success'))) : (React.createElement("div", null)),
                React.createElement("hr", null),
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_1' }, "Terminal"),
                React.createElement(Box, { as: 'span', layerStyle: 'options_3cols' },
                    React.createElement(Button, { onClick: evt => handleChange(evt), value: 1, variant: value.includes(1) ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button' }, t('reconciliation.infos')),
                    React.createElement(Button, { onClick: evt => handleChange(evt), value: 2, variant: value.includes(2) ? 'menuconfigpanel_option_button_secondary_activated' : 'menuconfigpanel_option_button_secondary' }, t('reconciliation.err')),
                    React.createElement(Button, { onClick: evt => handleChange(evt), value: 3, variant: value.includes(3) ? 'menuconfigpanel_option_button_tertiary_activated' : 'menuconfigpanel_option_button_tertiary' }, t('reconciliation.debug'))),
                processing ? (React.createElement(Counter, { value: value, result: result, setResult: setResult, setProcessing: setProcessing, setFailure: setFailure })) : (React.createElement(React.Fragment, null, infos.map((info) => (value.includes(2) && info.includes('ERROR') ?
                    (React.createElement("div", { style: { color: 'red' } }, info.replace('ERROR', '')))
                    : value.includes(1) && info.includes('INFO') && !info.includes('POST') ?
                        (React.createElement("div", { style: { color: 'blue' } }, info.replace('INFO', '')))
                        : value.includes(3) && (info.includes('DEBUG')) ?
                            (React.createElement("div", { style: { color: 'orange' } }, info.replace('DEBUG', ''))) : (null)))))))));
};
// ---------------------------------------------------------
const Counter = ({ value, result, setResult, setProcessing, setFailure }) => {
    useEffect(() => {
        // Get process status every 5ms ?
        const interval = setInterval(() => {
            const path = window.location.href;
            const url_optimize_prod_process = path + 'sankeytools/optimize_prod_process';
            const fetchData = {
                method: 'POST',
                body: ''
            };
            fetch(url_optimize_prod_process, fetchData).then(function (response) {
                if (response.ok) {
                    response.json().then(function (data) {
                        setResult(data.output);
                    });
                }
            });
        }, 5000);
        return () => clearInterval(interval);
    });
    // Detect for log message the current status of reconciliation
    const infos = (result !== undefined) ? result.split('\n') : [];
    if (infos.length > 2) {
        const info = infos[infos.length - 2];
        if (info.includes('[COMPLETED]')) {
            setProcessing(false);
            setFailure(false);
        }
        else if (info.includes('[FAILED]')) {
            setProcessing(false);
            setFailure(true);
        }
    }
    return (React.createElement(React.Fragment, null, infos.map(info => (value.includes(2) && info.includes('ERROR') ?
        (React.createElement("div", { style: { color: 'red' } }, info.replace('ERROR', '')))
        : value.includes(1) && info.includes('INFO') && !info.includes('POST') ?
            (React.createElement("div", { style: { color: 'blue' } }, info.replace('INFO', '')))
            : value.includes(3) && (info.includes('DEBUG')) ?
                (React.createElement("div", { style: { color: 'orange' } }, info.replace('DEBUG', ''))) : (null)))));
};
