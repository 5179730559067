// External libs
import * as d3 from 'd3';
// Local imports
import { format_link_value, trad_value_decimale } from './AFMSankeyMenu';
// OpenSankey overrided imports
import { LinkVisible, NodeDisplayed, ToPrecision } from '../import/OpenSankey';
export const AFMLinkTooltipsContent = (data, l, GetLinkValue, trad) => {
    const { tooltip_names, units_names } = data;
    const { nodes } = data;
    let t = '<p style=\'text-align: center;margin-bottom:0px\'><b>' + nodes[l.idSource].name.split('\\n').join(' ') + ' -> ' + nodes[l.idTarget].name.split('\\n').join(' ') + '</b></p>';
    if (l.tooltip_text) {
        //t += '<p><b>Définition'+ '</b></p>'
        t += '<p>' + l.tooltip_text.split('\n').join('</br>') + '</p>';
    }
    let default_unit_name = '';
    let unit_coeff = ' ';
    if (units_names && units_names[0] && units_names[0].length > 0) {
        default_unit_name = units_names[0][0];
        if (data.units_names[0][0].includes('k ')) {
            unit_coeff = ' k ';
        }
    }
    //- Données
    let children = false;
    let desagregate_source_nodes = [];
    let desagregate_target_nodes = [];
    //if ('flux_types' in l.tags && l.tags['flux_types'].includes('initial_data') && l.data_value && (l.data_value as number[])[value_index] !== -1) {
    const link_info = GetLinkValue(data, l.idLink);
    if (link_info.extension.data_value) {
        t += '<p>Donnée collectée </p>';
        // <thead>
        t += '<table class="table table-striped " >';
        t += '<thead><tr>';
        if (units_names && units_names[0]) {
            t += '<th>' + default_unit_name + '</th>';
            if (l.natural_unit) {
                t += '<th>' + unit_coeff + String(l.natural_unit).replace('tonnes', 't').replace('tonne', 't') + '</th>';
            }
        }
        else {
            t += '<th>Valeur</th>';
        }
        if (link_info.extension.data_source) {
            t += '<th>Source</th>';
        }
        t += '</tr></thead>';
        // <thead>
        t += '<tbody><tr>';
        t += '<td>' + format_link_value(data, l, +link_info.extension.data_value, trad) + '</td>';
        if (l.natural_unit) {
            t += '<td>' + format_link_value(data, l, link_info.extension.data_value * l.conv[1], trad) + '</td>';
        }
        if (link_info.extension.data_source) {
            t += '<td>' + link_info.extension.data_source + '</td>';
        }
        t += '</tr></tbody></table>';
    }
    else if (link_info.extension.data_min) {
        t += '<p>Bornes collectées </p>';
        t += '<table class="table table-striped " >';
        t += '<thead><tr>';
        // if (units_names && units_names[0] ) {
        //   t +=      '<th>'+default_unit_name+'</th>'
        //   if ( l.conv  ) {
        //     t +=    '<th>'+String(l.natural_unit).replace('tonnes','t').replace('tonne','t')+'</th>'
        //   }
        // } else {
        t += '<th>Minimum</th><th>Maximum</th>';
        //}
        if (link_info.extension.data_source) {
            t += '<th>Source</th>';
        }
        t += '</tr><tbody><tr>';
        t += '<td>' + link_info.extension.data_min + '</td>';
        t += '<td>' + link_info.extension.data_max + '</td>';
        // if ( l.conv  ) {
        //   t += '<td>' + TooltipLinkToPrecision(data,l,link_info.extension.data_value*l.conv[1] ) + '</td>'
        // }
        if (link_info.extension.data_source) {
            t += '<td>' + link_info.extension.data_source + '</td>';
        }
        t += '</tr></tbody></table>';
    }
    else {
        const source_node = nodes[l.idSource];
        const target_node = nodes[l.idTarget];
        desagregate_source_nodes = Object.values(data.nodes).filter(n => {
            for (const dim in n.dimensions) {
                if (n.dimensions[dim] !== undefined && n.dimensions[dim].parent_name) {
                    if (n.dimensions[dim].parent_name === source_node.idNode) {
                        return true;
                    }
                    else {
                        const parent_node = Object.values(data.nodes).filter(n2 => n.dimensions[dim].parent_name && (n2.idNode === n.dimensions[dim].parent_name))[0];
                        if (parent_node && parent_node.dimensions[dim] && parent_node.dimensions[dim].parent_name === source_node.idNode) {
                            return true;
                        }
                    }
                }
                return false;
            }
        });
        desagregate_target_nodes = Object.values(data.nodes).filter(n => {
            for (const dim in n.dimensions) {
                if (n.dimensions[dim] !== undefined && n.dimensions[dim].parent_name) {
                    const parent_name = n.dimensions[dim].parent_name;
                    if (parent_name === target_node.idNode) {
                        return true;
                    }
                    else {
                        const parent_node = Object.values(data.nodes).filter(n2 => n2.idNode === parent_name)[0];
                        if (parent_node && parent_node.dimensions[dim] && parent_node.dimensions[dim].parent_name === target_node.idNode) {
                            return true;
                        }
                    }
                }
            }
            return false;
        });
        children = desagregate_source_nodes.length > 0 || desagregate_target_nodes.length > 0;
        desagregate_target_nodes.push(target_node);
        desagregate_source_nodes.push(source_node);
        t = WriteChildrenTable(desagregate_source_nodes, desagregate_target_nodes, data, t, default_unit_name, l, true, GetLinkValue, trad);
    }
    // } else if (l.data_constraint) {
    //   t += '<b>Contraintes du modéle: % de  '+ l.source_name.split('\\n').join(' ') + '</b><ul style=\'margin-bottom:0px\'>'
    //   for (const constraint in l.data_constraint) {
    //     t += '<li>' + l.data_constraint[constraint] + '</li>'
    //   }
    // }
    // const unit_name = units_names && units_names.length != 0 ? default_unit_name : ''
    // Variables non libres
    if (!link_info.extension.free_mini) {
        if (link_info.extension.data_value) {
            if (children) {
                t = WriteChildrenTable(desagregate_source_nodes, desagregate_target_nodes, data, t, default_unit_name, l, false, GetLinkValue, trad);
            }
            t += '<p>Donnée réconciliée :</p>';
        }
        else {
            t += '<p>Donnée déterminée :</p>';
        }
        t += '<table class="table table-striped " >';
        if (units_names && units_names[0]) {
            t += '<thead><tr><th>Unités</th>';
            t += '<th>' + default_unit_name.replace('tonnes', 't').replace('tonne', 't') + '</th>';
            if (l.conv) {
                t += '<th>' + unit_coeff + String(l.natural_unit).replace('tonnes', 't').replace('tonne', 't') + '</th>';
            }
            if (units_names && units_names[0]) {
                for (let i = 2; i < units_names.length; i++) {
                    if (units_names[i][0].replace('tonnes', 't').replace('tonne', 't') == String(l.natural_unit).replace('tonnes', 't').replace('tonne', 't')) {
                        continue;
                    }
                    if (l.conv && l.conv[i] !== null) {
                        t += '<th>' + unit_coeff + units_names[i][0].replace('tonnes', 't').replace('tonne', 't') + '</th>';
                    }
                }
            }
            t += '</tr></thead>';
        }
        t += '<tbody><tr><th>Valeur</th>';
        let the_value = +link_info.value;
        if (link_info.display_value !== '') {
            the_value = Number(String(link_info.display_value).replace('*', ''));
        }
        t += '<td>' + format_link_value(data, l, the_value, trad) + '</td>';
        t += '</td>';
        if (l.conv) {
            if (l.natural_unit) {
                t += '<td>' + format_link_value(data, l, the_value * l.conv[1], trad);
                t += '</td>';
            }
            if (units_names && units_names[0]) {
                for (let i = 2; i < units_names.length; i++) {
                    if (units_names[i][0].replace('tonnes', 't').replace('tonne', 't') == String(l.natural_unit).replace('tonnes', 't').replace('tonne', 't')) {
                        continue;
                    }
                    if (l.conv[i] !== null) {
                        t += '<td>' + format_link_value(data, l, the_value * l.conv[i], trad);
                        t += '</td>';
                    }
                }
            }
        }
        t += '</tr>';
        const interval = link_info.extension.mini !== undefined && link_info.extension.maxi !== undefined && link_info.extension.mini !== null && link_info.extension.maxi !== null && link_info.extension.mini !== link_info.extension.maxi ? [link_info.extension.mini, link_info.extension.maxi] : undefined;
        if (interval) {
            t += '<tr><th>Bornes d\'incertitudes</th>' + '<td>' + interval[0] + ' - ' + interval[1] + '</td>';
            if (l.conv) {
                if (l.natural_unit) {
                    t += '<td>';
                    t += interval[0] * l.conv[1] + ' - ' + interval[1] * l.conv[1];
                    t += '</td>';
                }
                for (let i = 2; i < units_names.length; i++) {
                    if (units_names[i][0].replace('tonnes', 't').replace('tonne', 't') == String(l.natural_unit).replace('tonnes', 't').replace('tonne', 't')) {
                        continue;
                    }
                    if (l.conv[i] !== null) {
                        t += '<td>';
                        t += interval[0] * l.conv[i] + ' - ' + interval[1] * l.conv[i];
                        t += '</td>';
                    }
                }
            }
        }
        // Object.entries(data.fluxTags).forEach(link_info_tag=> {
        //   const flux_tag_names : string[] =[]
        //   if ( link_info_tag !== null ) {
        //     //link_info.tags[tag[0]].filter(litag=>data.fluxTags[tag[0]].tags[litag]).forEach(litag=>flux_tag_names.push(data.fluxTags[tag[0]].tags[litag].name))
        //     flux_tag_names.push(data.fluxTags[tag_group_key].tags[link_info_tag].name)
        //   }
        //   t+='<tr><th>'+tag[1].group_name+'</th>'+'<td>'+flux_tag_names.join()+'</td></tr>'
        // })
        Object.entries(link_info.tags).forEach(([tag_group_key, tags]) => {
            const names = [];
            tags.forEach(link_info_tag => {
                if (link_info_tag !== null) {
                    names.push(data.fluxTags[tag_group_key].tags[link_info_tag].name);
                }
            });
            t += '<tr><th>' + data.fluxTags[tag_group_key].group_name + '</th><td>' + names.join() + '</td><tr>';
        });
        t += '</tbody></table>';
    }
    else {
        // Variables libres
        // let tmp
        // if (link_info.display_value.includes('-')) {
        //   tmp = link_info.display_value.split('-')
        // } else if (link_info.display_value.includes(',')) {
        //   tmp = link_info.display_value.split(',')
        // } else if (link_info.display_value.includes('...')) {
        //   tmp = link_info.display_value.split('...')
        // } else {
        //   tmp = link_info.display_value.split('  ')
        // }
        const mini = link_info.extension.free_mini;
        const maxi = link_info.extension.free_maxi;
        if (mini !== maxi) {
            t += '<table class="table table-striped " >';
            if (units_names && units_names[0]) {
                t += '<thead><tr><th></th>';
                t += '<th>' + default_unit_name.replace('tonnes', 't').replace('tonne', 't') + '</th>';
                if (l.conv) {
                    t += '<th>' + unit_coeff + String(l.natural_unit).replace('tonnes', 't').replace('tonne', 't') + '</th>';
                }
                for (let i = 2; i < units_names.length; i++) {
                    if (units_names[i][0].replace('tonnes', 't').replace('tonne', 't') == String(l.natural_unit).replace('tonnes', 't').replace('tonne', 't')) {
                        continue;
                    }
                    if (l.conv && l.conv[i] !== null) {
                        t += '<th>' + unit_coeff + units_names[i][0].replace('tonnes', 't').replace('tonne', 't') + '</th>';
                    }
                }
                t += '</tr></thead>';
            }
            t += '<tbody><tr>';
            t += '<td>Intervalle</td><td>[' + format_link_value(data, l, mini, trad) + '  ' + format_link_value(data, l, maxi, trad) + ']' + '</td>';
            if (l.conv) {
                if (l.natural_unit) {
                    t += '<td>[' + format_link_value(data, l, mini * l.conv[1], trad) + '  ' + format_link_value(data, l, maxi * l.conv[1], trad) + ']' + '</td>';
                }
                for (let i = 2; i < units_names.length; i++) {
                    if (units_names[i][0].replace('tonnes', 't').replace('tonne', 't') == String(l.natural_unit).replace('tonnes', 't').replace('tonne', 't')) {
                        continue;
                    }
                    if (l.conv[i] !== null) {
                        t += '<td>[' + format_link_value(data, l, mini * l.conv[i], trad) + '  ' + format_link_value(data, l, maxi * l.conv[i], trad) + ']' + '</td>';
                    }
                }
            }
            t += '</tr></tbody></table>';
        }
        else {
            t += '<table class="table table-striped " >';
            if (units_names && units_names[0]) {
                t += '<thead><tr>';
                t += '<th>' + default_unit_name.replace('tonnes', 't').replace('tonne', 't') + '</th>';
                if (l.conv) {
                    t += '<th>' + unit_coeff + String(l.natural_unit).replace('tonnes', 't').replace('tonne', 't') + '</th>';
                }
                for (let i = 2; i < units_names.length; i++) {
                    if (units_names[i][0].replace('tonnes', 't').replace('tonne', 't') == String(l.natural_unit).replace('tonnes', 't').replace('tonne', 't')) {
                        continue;
                    }
                    if (l.conv && l.conv[i] !== null) {
                        t += '<th>' + unit_coeff + units_names[i][0].replace('tonnes', 't').replace('tonne', 't') + '</th>';
                    }
                }
                t += '</tr></thead>';
            }
            t += '<tr><td>Valeur</td><td>' + mini + '</td>';
            if (l.conv) {
                t += '<td>' + mini * l.conv[1] + '</td>';
                for (let i = 2; i < units_names.length; i++) {
                    if (units_names[i][0].replace('tonnes', 't').replace('tonne', 't') == String(l.natural_unit).replace('tonnes', 't').replace('tonne', 't')) {
                        continue;
                    }
                    if (l.conv[i] !== null) {
                        t += '<td>' + mini * l.conv[i] + '</td>';
                    }
                }
            }
        }
        t += '</tr>';
        t += '</tbody></table>';
    }
    if (l.tooltips) {
        let title = false;
        const count = tooltip_names.filter((tooltip_name, i) => {
            return (l.tooltips && l.tooltips[i] !== undefined && l.tooltips[i] !== null);
        }).length;
        const width = 100 / count + 'px';
        tooltip_names.forEach((tooltip_name, i) => {
            if (l.tooltips && l.tooltips[i] !== undefined && l.tooltips[i] !== null) {
                if (title === false) {
                    t += '<p>Hypothèses</p>';
                    t += '<table class="table table-striped " >';
                    t += '<thead><tr>';
                    title = true;
                }
                t += '<th width="' + width + '">' + tooltip_name[0] + '</th>';
            }
        });
        t += '</tr></thead>';
        t += '<tr><tbody>';
        tooltip_names.forEach((_, i) => {
            if (l.tooltips && l.tooltips[i] !== undefined && l.tooltips[i] !== null) {
                if (typeof l.tooltips[i] === 'number') {
                    if (tooltip_names[i].includes('%')) {
                        t += '<td>' + format_link_value(data, l, Number(l.tooltips[i]) * 100, trad) + '</td>';
                    }
                    else {
                        t += '<td>' + format_link_value(data, l, +l.tooltips[i], trad) + '</td>';
                    }
                }
                else {
                    t += '<td>' + l.tooltips[i] + '</td>';
                }
            }
        });
        t += '</tr></tbody></table>';
    }
    return t;
};
export const AFMNodeTooltipsContent = (data, display_nodes, n, GetLinkValue, trad) => {
    const { units_names } = data;
    const { nodes, links } = data;
    let unit_coeff = ' ';
    if (units_names && units_names[0] && data.units_names[0].length > 0) {
        if (data.units_names[0][0].includes('k ')) {
            unit_coeff = ' k ';
        }
    }
    let t = '<p class="title" style="margin-bottom: 5px;">' + n.name.split('\\n').join(' ') + '</p>';
    //t += '<p class="title" style="margin-bottom: 5px;">'  + 'u: '+n.u + ' v: ' +n.v + ' dy: ' + ReturnValueNode(data,n,'dy') + ' dy: ' + '</p>'
    //t += '<p class="title" style="margin-bottom: 5px;">'  + 'relative_dx: ' + ReturnValueNode(data,n,'relative_dx') +'</p>'
    //t += '<p class="title" style="margin-bottom: 5px;">'  + 'relative_dy: ' + ReturnValueNode(data,n,'relative_dy') +'</p>'
    t += '<div style="padding-left :5px;padding-right :5px">';
    if (n.tooltip_text) {
        //t += '<p><b>Définition'+ '</b></p>'
        //t += '<p class="subtitle">'+n.tooltip_text.split('\n').join('</br>')+ '</p>'
        t += '<p class="subtitle" style="	margin-bottom: 5px;">' + n.tooltip_text.split('\n').join('<br>') + '</p>';
    }
    let total = 0;
    let total_format = 0;
    let has_natural_unit = false;
    if (n.inputLinksId.length > 0) {
        for (let i = 0; i < n.inputLinksId.length; i++) {
            const link = links[n.inputLinksId[i]];
            if (link === undefined) {
                //alert('Corruption du diagramme')
                return '';
            }
            if (!LinkVisible(link, data, display_nodes)) {
                continue;
            }
            const link_info = GetLinkValue(data, link.idLink);
            let the_value = link_info.value;
            if ('display_value' in link_info && link_info.display_value !== '' && !link_info.display_value.includes('[')) {
                the_value = Number(String(link_info.display_value).replace('*', ''));
            }
            if (NodeDisplayed(data, nodes[link.idSource]) && NodeDisplayed(data, nodes[link.idTarget])) {
                total += (the_value) ? the_value : 0;
                const v = format_link_value(data, link, the_value, trad);
                if (v) {
                    total_format += Number(v);
                }
                if (link.conv && link.natural_unit) {
                    has_natural_unit = true;
                }
            }
        }
    }
    //t += '<br>'
    if (n.inputLinksId.length > 0) {
        t += '<p class="tab-title" style="margin-bottom: 5px;">Entrées' + '</p>';
        t += '<table class="table" style="margin-bottom: 5px;">';
        t += '<thead><tr>';
        t += '<table class="table" style="margin-bottom: 5px;">';
        t += '<thead><tr>';
        if (units_names && units_names[0]) {
            if (has_natural_unit) {
                t += '<th width="150px"></th><th width="120px">Unités naturelles</th><th width="120px">Unité équivalente</th><th width="120px">Pourcentage</th>';
            }
            else {
                t += '<th width="150px"></th><th>' + units_names[0][0] + '</th><th width="120px">Pourcentage</th>';
            }
            //t +=      '<th width="150px"></th><th width="120px">Unités naturelles</th><th width="120px">Unité équivalente</th><th width="120px">Pourcentage</th>'
        }
        else {
            t += '<th width="150px"></th><th width="120px">Valeur</th><th width="120px">Pourcentage</th>';
        }
        Object.values(data.fluxTags).forEach(tag => t += '<th>' + tag.group_name + '</th>');
        t += '</tr></thead>';
        for (let i = 0; i < n.inputLinksId.length; i++) {
            const link = links[n.inputLinksId[i]];
            if (link === undefined) {
                //alert('Corruption du diagramme')
                return '';
            }
            const link_info = GetLinkValue(data, link.idLink);
            let the_value = link_info.value;
            if (link_info.display_value == 'missing') {
                continue;
            }
            if (!LinkVisible(link, data, display_nodes)) {
                continue;
            }
            if ('display_value' in link_info && link_info.display_value !== '' && !link_info.display_value.includes('[')) {
                the_value = Number(String(link_info.display_value).replace('*', ''));
            }
            if (NodeDisplayed(data, nodes[link.idSource]) && NodeDisplayed(data, nodes[link.idTarget])) {
                const source_name = nodes[link.idSource].name.split('\\n').join(' ');
                t += '<tr><td style="white-space: nowrap;" >' + source_name + '</td>';
                if (link.conv && link.natural_unit) {
                    const val = format_link_value(data, link, (the_value ? the_value : 0) * link.conv[1], trad);
                    t += '<td>' + trad_value_decimale(val, trad) + unit_coeff + link.natural_unit.replace('tonnes', 't').replace('tonne', 't') + '</td>';
                }
                else if (has_natural_unit && units_names && units_names[0]) {
                    t += '<td></td>';
                }
                t += '<td>' + trad_value_decimale(the_value ? the_value : 0, trad);
                if (has_natural_unit && units_names && units_names[0]) {
                    t += ' ' + units_names[0][0];
                }
                if (n.inputLinksId.length > 1) {
                    const percent = Math.round(GetLinkValue(data, link.idLink).value * 100 / total);
                    t += '<td>' + percent + '%</td>';
                    Object.keys(data.fluxTags).forEach(tag_group_key => {
                        const names = [];
                        if (!link_info.tags[tag_group_key]) {
                            return;
                        }
                        link_info.tags[tag_group_key].filter(t => t !== null && t !== undefined).forEach(tag => {
                            if (tag !== null) {
                                names.push(data.fluxTags[tag_group_key].tags[tag].name);
                            }
                        });
                        t += '<td style="white-space: nowrap;">' + names.join() + '</td></tr>';
                    });
                }
                else {
                    t += '<td></td></tr>';
                }
            }
        }
        if (has_natural_unit && units_names && units_names[0]) {
            t += '<tr><th>Total</th><td></td><td>' + (data.show_structure != 'free_interval' ? ToPrecision(Number(total_format), trad, data.style_link['default']['scientific_precision']) : total) + ' ' + units_names[0][0] + '</td><td></td>';
        }
        else {
            t += '<tr><th>Total</th><td>' + (data.show_structure != 'free_interval' ? ToPrecision(Number(total_format), trad, data.style_link['default']['scientific_precision']) : total) + '</td><td></td>';
        }
        Object.keys(data.fluxTags).forEach(() => t += '<td></td>');
        t += '</tr></tbody></table>';
    }
    total = 0;
    total_format = 0;
    if (n.outputLinksId.length > 0) {
        for (let i = 0; i < n.outputLinksId.length; i++) {
            const link = links[n.outputLinksId[i]];
            if (link === undefined) {
                //alert('Corruption du diagramme')
                return '';
            }
            if (!LinkVisible(link, data, display_nodes)) {
                continue;
            }
            const link_info = GetLinkValue(data, link.idLink);
            if (link_info.display_value == 'missing') {
                continue;
            }
            let the_value = link_info.value;
            if ('display_value' in link_info && link_info.display_value !== '' && !link_info.display_value.includes('[')) {
                the_value = Number(String(link_info.display_value).replace('*', ''));
            }
            if (NodeDisplayed(data, nodes[link.idSource]) && NodeDisplayed(data, nodes[link.idTarget])) {
                total += (the_value) ? the_value : 0;
                const v = (the_value ? the_value : 0);
                if (v) {
                    total_format += v;
                }
                if (link.conv && link.natural_unit) {
                    has_natural_unit = true;
                }
            }
        }
        if (n.outputLinksId.length > 0) {
            t += '<p class="tab-title" style="margin-bottom: 5px;">Sorties' + '</p>';
            t += '<table class="table" style="margin-bottom: 5px;">';
            t += '<thead><tr>';
            if (units_names && units_names[0]) {
                if (has_natural_unit) {
                    t += '<th width="150px"></th><th width="120px">Unités naturelles</th><th width="120px">Unité équivalente</th><th width="120px">Pourcentage</th>';
                }
                else {
                    t += '<th width="150px"></th><th>' + units_names[0][0] + '</th><th width="120px">Pourcentage</th>';
                }
                //t +=      '<th width="150px"></th><th width="120px">Unités naturelles</th><th width="120px">Unité équivalente</th><th width="120px">Pourcentage</th>'
            }
            else {
                t += '<th width="150px"></th><th width="120px">Valeur</th><th width="120px">Pourcentage</th>';
            }
            Object.values(data.fluxTags).forEach(tag => t += '<th>' + tag.group_name + '</th>');
            t += '</tr></thead>';
            for (let i = 0; i < n.outputLinksId.length; i++) {
                const link = links[n.outputLinksId[i]];
                if (link === undefined) {
                    //alert('Corruption du diagramme')
                    return '';
                }
                const link_info = GetLinkValue(data, link.idLink);
                if (!LinkVisible(link, data, display_nodes)) {
                    continue;
                }
                let the_value = link_info.value;
                if ('display_value' in link_info && link_info.display_value !== '' && !link_info.display_value.includes('[')) {
                    the_value = Number(String(link_info.display_value).replace('*', ''));
                }
                if (NodeDisplayed(data, nodes[link.idSource]) && NodeDisplayed(data, nodes[link.idTarget])) {
                    const target_name = nodes[link.idTarget].name.split('\\n').join(' ');
                    t += '<tr><td style="white-space: nowrap;">' + target_name + '</td>';
                    if (link.conv && link.natural_unit) {
                        const val = format_link_value(data, link, the_value * link.conv[1], trad);
                        t += '<td>' + trad_value_decimale(val, trad) + unit_coeff + link.natural_unit.replace('tonnes', 't').replace('tonne', 't') + '</td>';
                    }
                    else if (has_natural_unit && units_names && units_names[0]) {
                        t += '<td></td>';
                    }
                    t += '<td>' + trad_value_decimale(format_link_value(data, link, the_value, trad), trad);
                    if (has_natural_unit && units_names && units_names[0]) {
                        t += ' ' + units_names[0][0];
                    }
                    if (n.outputLinksId.length > 1) {
                        const percent = Math.round(GetLinkValue(data, link.idLink).value * 100 / total);
                        t += '<td>' + percent + '%</td>';
                        Object.keys(data.fluxTags).forEach(tag_group_key => {
                            const names = [];
                            if (!link_info.tags[tag_group_key]) {
                                return;
                            }
                            link_info.tags[tag_group_key].filter(t => t !== null && t !== undefined).forEach(tag => {
                                if (tag !== null) {
                                    names.push(data.fluxTags[tag_group_key].tags[tag].name);
                                }
                            });
                            t += '<td style="white-space: nowrap;">' + names.join() + '</td></tr>';
                        });
                    }
                    else {
                        t += '<td></td></tr>';
                    }
                }
            }
        }
        if (has_natural_unit && units_names && units_names[0]) {
            t += '<tr><th>Total</th><td></td><td>' + (data.show_structure != 'free_interval' ? ToPrecision(Number(total_format), trad, data.style_link['default']['scientific_precision']) : total) + ' ' + units_names[0][0] + '</td><td></td>';
        }
        else {
            t += '<tr><th>Total</th><td>' + (data.show_structure != 'free_interval' ? ToPrecision(Number(total_format), trad, data.style_link['default']['scientific_precision']) : total) + '</td><td></td>';
        }
        Object.keys(data.fluxTags).forEach(() => t += '<td></td>');
        t += '</tr></tbody></table>';
    }
    if (!n.dimensions) {
        return t;
    }
    // let header_written = false
    // sometime the node has dimensions that are not levelTag, I don't know if they are error
    // but to avoid the application crash I test if data.levelTags[dim] return at least something
    // Object.keys(n.dimensions)
    //   .filter(dim=> data.levelTags[dim] && data.levelTags[dim].activated === true)
    //   .forEach( dim=> {
    //     let has_parent = false
    //     if (n.dimensions[dim].parent_name && nodes[n.dimensions[dim].parent_name as string] ) {
    //       if (! header_written) {
    //         t += '<br><p><b>Noeuds parents et enfants<b></p>'
    //         t += '<table class="table table-striped " ><thead><tr><th width="50%">Noeuds parents</th><th width="50%">Noeuds enfants</th></tr></thead><tbody><tr>'
    //         header_written = true
    //       }
    //       has_parent = true
    //       t += '<td style="white-space: nowrap;">' + nodes[n.dimensions[dim].parent_name as string].name +'</td>'
    //     }
    //     const desagregate_nodes = Object.values(data.nodes).filter( node => node.dimensions[dim] && node.dimensions[dim].parent_name === n.idNode )
    //     if (desagregate_nodes.length>0) {
    //       if (! header_written) {
    //         t += '<br><p><b>Noeuds agrégés et désagrégés</b></p>'
    //         t += '<table class="table table-striped " ><thead><tr><th width="50%">Noeuds parents</th><th width="50%">Noeuds enfants</th></tr></thead><tbody><tr>'
    //         header_written = true
    //       }
    //       if (!has_parent) {
    //         t += '<td></td>'
    //       }
    //       t += '<td style="white-space: nowrap;">'
    //       desagregate_nodes.forEach(n=> t+=n.name+'<br>')
    //       t += '</td>'
    //     } else {
    //       t += '<td></td>'
    //     }
    //     t += '</tr>'
    //   })
    t += '</tbody></table>';
    t += '</div>';
    return t;
};
export const set_animation_tooltips = (animation_tooltips) => {
    Object.keys(animation_tooltips).forEach(pos => {
        const svg = d3.select('#svg');
        const tooltip = svg.append('g')
            .attr('class', 'tooltip')
            .attr('id', 'main_tooltip' + pos);
        tooltip.append('rect')
            .attr('class', 'rect_tooltip')
            .attr('id', 'rect_main_tooltip' + pos)
            .attr('rx', 5)
            .attr('height', 50)
            .attr('width', 100);
        tooltip.append('text')
            .attr('class', 'text_tooltip')
            .attr('id', 'text_main_tooltip' + pos);
        // d3.select('#text_main_tooltip'+pos)
        //   .append('tspan')
        //   .attr('id','main_tooltip'+'span')
        //   .attr('x',10)
        //   .attr('dy',10)
        //   .attr('class','text_tooltip')
        //   .text(animation_tooltips[pos])
        let max_text_length = 0;
        const tooltip_lines = animation_tooltips[pos].split('\\n');
        let dy = '1em';
        tooltip_lines.forEach((e, r) => {
            let tooltip_class = 'text_tooltip';
            if (e.includes('<b>')) {
                e = e.substring(3);
                tooltip_class = 'text_tooltip_title';
            }
            if (e === '') {
                dy = '2em';
                return;
            }
            d3.select('#text_main_tooltip' + pos)
                .append('tspan')
                .attr('id', 'text_main_tooltip_span' + r)
                .attr('x', 10)
                .attr('dy', dy)
                .attr('class', tooltip_class)
                .text(e);
            dy = '1em';
            const tooltip_el = d3.select('#text_main_tooltip_span' + r).node();
            if (tooltip_el) {
                const text_length = tooltip_el.getComputedTextLength();
                if (text_length >= max_text_length) {
                    max_text_length = text_length;
                    //d3.select('#rect_tooltip_'+suffix+i)
                }
            }
        });
        if (tooltip_lines.length > 1 || tooltip_lines[0] !== '') {
            d3.select('#rect_main_tooltip' + pos)
                .attr('width', max_text_length + 80)
                .attr('height', () => {
                const tooltip_text = animation_tooltips[pos].split('<br>').join('\\n');
                const count_br = (tooltip_text.match(/\\n/g) || []).length;
                return Math.max(50, count_br * 20);
            });
        }
    });
};
function WriteChildrenTable(desagregate_source_nodes, desagregate_target_nodes, data, t, default_unit_name, l, is_data, GetLinkValue, trad) {
    const { nodes } = data;
    let header_written = false;
    desagregate_source_nodes.forEach(n1 => {
        desagregate_target_nodes.forEach(n2 => {
            const desagregated_link = Object.values(data.links).filter(l => l.idSource === n1.idNode && l.idTarget === n2.idNode)[0];
            if (desagregated_link === undefined) {
                return;
            }
            const desagregated_link_info = GetLinkValue(data, desagregated_link.idLink);
            if (desagregated_link_info.extension.data_value) {
                if (!header_written) {
                    t += is_data ? '<p>Données collectées sur les flux désagrégés </p>' : '<p>Données réconciliées sur les flux désagrégés </p>';
                    t += '<table class="table table-striped " >';
                    t += '<thead><tr>';
                    t += '<th>Flux désagrégés</th>';
                    if (default_unit_name !== '') {
                        t += '<th>' + default_unit_name + '</th>';
                        t += '<th>Unité naturelle</th>';
                    }
                    else {
                        t += '<th>Valeur</th>';
                    }
                    if (is_data) {
                        t += '<th>Source</th>';
                    }
                    t += '</tr></thead>';
                    t += '<tbody>';
                    header_written = true;
                }
                let value_to_display = is_data ? +desagregated_link_info.extension['data_value'] : +desagregated_link_info['value'];
                if (!is_data && desagregated_link_info.display_value && desagregated_link_info.display_value.includes('*')) {
                    value_to_display = +String(GetLinkValue(data, desagregated_link.idLink).display_value).replace('*', '');
                }
                t += '<tr>';
                t += '<td>' + nodes[desagregated_link.idSource].name + '->' + nodes[desagregated_link.idTarget].name + '</td>';
                t += '<td>' + format_link_value(data, desagregated_link, value_to_display, trad) + '</td>';
                if (desagregated_link.conv && desagregated_link.natural_unit) {
                    t += '<td>' + format_link_value(data, desagregated_link, value_to_display * desagregated_link.conv[1], trad) + ' ' + desagregated_link.natural_unit + '</td>';
                }
                else if (l.conv && desagregated_link.natural_unit) {
                    t += '<td>' + format_link_value(data, desagregated_link, value_to_display * l.conv[1], trad) + ' ' + desagregated_link.natural_unit + '</td>';
                } //else {
                //   t += '<td></td>'
                // }
                if (is_data) {
                    if (desagregated_link_info.extension.data_source) {
                        t += '<td>' + desagregated_link_info.extension.data_source + '</td>';
                    }
                    else {
                        t += '<td></td>';
                    }
                    t += '<td></td>';
                }
                t += '</tr>';
            }
        });
    });
    if (header_written) {
        t += '</tr></tbody></table>';
    }
    return t;
}
