import React, { useState, } from 'react';
import { complete_sankey_data } from '../configmenus/SankeyConvert';
import { DefaultLink, DefaultNode, OSTooltip, ReturnValueNode } from '../configmenus/SankeyUtils';
import { arrangeNodes, ComputeAutoSankey, ComputeParametrization } from '../draw/SankeyDrawLayout';
import { MenuDraggable } from '../topmenus/SankeyMenuTop';
import { FaCheck } from 'react-icons/fa';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Checkbox, Button, NumberInput, Input, NumberDecrementStepper, NumberIncrementStepper, NumberInputField, NumberInputStepper, TabList, Tab, Tabs, TabPanels, TabPanel, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton } from '@chakra-ui/react';
export const os_all_element_to_transform = [
    'addNode', 'addFlux', 'removeNode', 'removeFlux',
    'posNode', 'posFlux',
    'Values',
    'attrNode', 'attrFlux',
    'tagNode', 'tagFlux', 'tagData', 'tagLevel',
    'attrGeneral'
];
/**
 *
 * @param {ApplyLayoutDialogTypes} { ref_setter_show_apply_layout, set_show_apply_layout, sankey_data, set_sankey_data }
 * @returns {*}
 */
export const ApplyLayoutDialog = ({ t, dict_hook_ref_setter_show_dialog_components, applicationData, applicationDraw, convert_data, diagramSelector, apply_transformation_additional_elements, DefaultSankeyData, ComponentUpdater }) => {
    const { updateLayout, all_element_UpdateLayout } = applicationDraw;
    const { data, set_data, dataVarToUpdate } = applicationData;
    const [prev_sankey_data, set_prev_sankey_data] = useState(data);
    const [forceUpdate, setForceUpdate] = useState(true);
    // const [stretchFactorH,]=useState(1)
    // const [stretchFactorV,]=useState(1)
    const [mode_trans, set_mode_trans] = useState('simple');
    const [parametric, set_parametric] = useState(data.style_node['default'].position == 'parametric');
    const [trade_close, set_trade_close] = useState(true);
    const [node_hspace, set_node_hspace] = useState(data.style_node['default'].dx);
    const [node_vspace, set_node_vspace] = useState(data.style_node['default'].dy);
    if (node_hspace !== data.style_node['default'].dx) {
        set_node_hspace(data.style_node['default'].dx);
    }
    if (node_vspace !== data.style_node['default'].dy) {
        set_node_vspace(data.style_node['default'].dy);
    }
    if (parametric !== (data.style_node['default'].position == 'parametric')) {
        set_parametric(data.style_node['default'].position == 'parametric');
    }
    // const node_visible=NodeVisibleOnsSvg()
    const simple_element_to_transform = [
        'posNode', 'posFlux',
        'attrNode', 'attrFlux',
        'attrGeneral'
    ];
    const default_element_to_transform = [
        'posNode', 'posFlux',
        'attrNode', 'attrFlux',
        'attrGeneral'
    ];
    const setTrade = (trade_close) => {
        if (trade_close) {
            data.style_node['NodeImportStyle'].position = 'relative';
            data.style_node['NodeImportStyle'].shape_visible = false;
            data.style_node['NodeImportStyle'].node_height = 40;
            data.style_node['NodeImportStyle'].label_visible = false;
            data.style_node['NodeImportStyle'].show_value = true;
            data.style_node['NodeImportStyle'].label_horiz_valeur = 'middle';
            data.style_node['NodeImportStyle'].label_vert_valeur = 'top';
            data.style_node['NodeExportStyle'].position = 'relative';
            data.style_node['NodeExportStyle'].shape_visible = false;
            data.style_node['NodeExportStyle'].node_height = 40;
            data.style_node['NodeExportStyle'].label_visible = false;
            data.style_node['NodeExportStyle'].show_value = true;
            data.style_node['NodeExportStyle'].label_horiz_valeur = 'middle';
            data.style_node['NodeExportStyle'].label_vert_valeur = 'bottom';
            data.style_link['LinkImportStyle'].orientation = 'vh';
            data.style_link['LinkExportStyle'].orientation = 'hv';
            set_data(Object.assign({}, data));
        }
        else {
            data.style_node['NodeImportStyle'].position = 'parametric';
            data.style_node['NodeImportStyle'].shape_visible = false;
            data.style_node['NodeImportStyle'].node_height = 1;
            data.style_node['NodeImportStyle'].label_visible = true;
            data.style_node['NodeImportStyle'].label_horiz = 'right';
            data.style_node['NodeImportStyle'].label_horiz_shift = -200;
            data.style_node['NodeImportStyle'].show_value = true;
            data.style_node['NodeImportStyle'].label_horiz_valeur = 'left';
            data.style_node['NodeImportStyle'].label_vert_valeur = 'middle';
            data.style_node['NodeImportStyle'].label_horiz_valeur_shift = -20;
            data.style_node['NodeExportStyle'].position = 'parametric';
            data.style_node['NodeExportStyle'].shape_visible = false;
            data.style_node['NodeExportStyle'].node_height = 1;
            data.style_node['NodeExportStyle'].label_visible = true;
            data.style_node['NodeExportStyle'].label_horiz = 'left';
            data.style_node['NodeExportStyle'].label_horiz_shift = 200;
            data.style_node['NodeExportStyle'].show_value = true;
            data.style_node['NodeExportStyle'].label_horiz_valeur = 'right';
            data.style_node['NodeExportStyle'].label_vert_valeur = 'middle';
            data.style_node['NodeExportStyle'].label_horiz_valeur_shift = 20;
            data.style_link['LinkImportStyle'].orientation = 'hh';
            data.style_link['LinkImportStyle'].label_visible = false;
            data.style_link['LinkExportStyle'].orientation = 'hh';
            data.style_link['LinkExportStyle'].label_visible = false;
            set_data(Object.assign({}, data));
        }
    };
    // const applyStretch=(param:string)=>{
    //   const attr=param=='h'?'x':'y'
    //   const stretchFactor=param=='h'?stretchFactorH:stretchFactorV
    //   let min=Object.values(data.nodes)[0][attr]
    //   // Cheche la position en y du noeud le plus en haut à gauche
    //   Object.values(data.nodes).filter(n=>node_visible.includes(n.idNode) && ReturnValueNode(data,n,'position')!='relative').forEach(n=>{
    //     min=(n[attr]<min)?n[attr]:min
    //   })
    //   // Parcours les noeuds --> calcule le delta des position en y entre ceux-ci --> multiplie le delta par le facteur du input -->
    //   // applique le delta mutiplié par le facteur au noeud
    //   Object.values(data.nodes).filter(n=>node_visible.includes(n.idNode) && ReturnValueNode(data,n,'position')!='relative').forEach(n=>{
    //     const delta=n[attr]-min
    //     n[attr]=min+(delta*stretchFactor)
    //   })
    //   set_data({...data})
    // }
    const content_modal_layout = React.createElement(Tabs, null,
        React.createElement(TabList, null,
            React.createElement(Box, { layerStyle: 'submenuconfig_tab' },
                React.createElement(Tab, null, t('Menu.Transformation.amp_import'))),
            React.createElement(Box, { layerStyle: 'submenuconfig_tab' },
                React.createElement(Tab, null, t('Menu.Transformation.amp_manuelle')))),
        React.createElement(TabPanels, null,
            React.createElement(TabPanel, null,
                React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
                    diagramSelector(t, convert_data, data, set_data, prev_sankey_data, set_prev_sankey_data, updateLayout, dataVarToUpdate, DefaultSankeyData),
                    React.createElement("hr", { style: { borderStyle: 'none', margin: '10px', color: 'grey', backgroundColor: 'grey', height: 2 } }),
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                        React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.choseTransforDifficulty')),
                        React.createElement(Box, { layerStyle: 'options_3cols' },
                            React.createElement(Button, { variant: mode_trans == 'simple' ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => { set_mode_trans('simple'); } }, "Basiques"),
                            React.createElement(Button, { variant: mode_trans == 'expert' ? 'menuconfigpanel_option_button_tertiary_activated' : 'menuconfigpanel_option_button_tertiary', onClick: () => { set_mode_trans('expert'); } }, "Tous"))),
                    React.createElement(OSTooltip, { label: t('Menu.Transformation.tooltips.Shortcuts') },
                        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.Shortcuts')),
                            React.createElement(Box, { layerStyle: 'options_4cols' },
                                React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                                        dataVarToUpdate.current.length = 0;
                                        ComponentUpdater.updateComponentBtnUpdateLayout.current();
                                        setForceUpdate(!forceUpdate);
                                    } }, t('Menu.Transformation.unSelectAll')),
                                React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                                        dataVarToUpdate.current.length = 0;
                                        if (mode_trans === 'simple') {
                                            simple_element_to_transform.forEach(el => dataVarToUpdate.current.push(el));
                                        }
                                        else {
                                            all_element_UpdateLayout.forEach(el => dataVarToUpdate.current.push(el));
                                        }
                                        ComponentUpdater.updateComponentBtnUpdateLayout.current();
                                        setForceUpdate(!forceUpdate);
                                    } }, t('Menu.Transformation.selectAll')),
                                React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                                        dataVarToUpdate.current.length = 0;
                                        default_element_to_transform.forEach(el => dataVarToUpdate.current.push(el));
                                        ComponentUpdater.updateComponentBtnUpdateLayout.current();
                                        setForceUpdate(!forceUpdate);
                                    } }, t('Menu.Transformation.selectDefault'))))),
                    mode_trans != 'simple' ?
                        React.createElement(OSTooltip, { label: t('Menu.Transformation.tooltips.Topology') },
                            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.Topology')),
                                React.createElement(Box, { layerStyle: 'options_4cols' },
                                    React.createElement(Button, { variant: dataVarToUpdate.current.includes('addNode') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                            if (!dataVarToUpdate.current.includes('addNode')) {
                                                dataVarToUpdate.current.push('addNode');
                                                setForceUpdate(!forceUpdate);
                                            }
                                            else {
                                                dataVarToUpdate.current.splice(dataVarToUpdate.current.indexOf('addNode'), 1);
                                                setForceUpdate(!forceUpdate);
                                            }
                                        } }, t('Menu.Transformation.addNode')),
                                    React.createElement(Button, { variant: dataVarToUpdate.current.includes('removeNode') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                            if (!dataVarToUpdate.current.includes('removeNode')) {
                                                dataVarToUpdate.current.push('removeNode');
                                                setForceUpdate(!forceUpdate);
                                            }
                                            else {
                                                dataVarToUpdate.current.splice(dataVarToUpdate.current.indexOf('removeNode'), 1);
                                                setForceUpdate(!forceUpdate);
                                            }
                                        } }, t('Menu.Transformation.removeNode')),
                                    React.createElement(Button, { variant: dataVarToUpdate.current.includes('addFlux') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                            if (!dataVarToUpdate.current.includes('addFlux')) {
                                                dataVarToUpdate.current.push('addFlux');
                                                setForceUpdate(!forceUpdate);
                                            }
                                            else {
                                                dataVarToUpdate.current.splice(dataVarToUpdate.current.indexOf('addFlux'), 1);
                                                setForceUpdate(!forceUpdate);
                                            }
                                        } }, t('Menu.Transformation.addFlux')),
                                    React.createElement(Button, { variant: dataVarToUpdate.current.includes('removeFlux') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                            if (!dataVarToUpdate.current.includes('removeFlux')) {
                                                dataVarToUpdate.current.push('removeFlux');
                                                setForceUpdate(!forceUpdate);
                                            }
                                            else {
                                                dataVarToUpdate.current.splice(dataVarToUpdate.current.indexOf('removeFlux'), 1);
                                                setForceUpdate(!forceUpdate);
                                            }
                                        } }, t('Menu.Transformation.removeFlux'))))) : React.createElement(React.Fragment, null),
                    React.createElement(OSTooltip, { label: t('Menu.Transformation.tooltips.Geometry') },
                        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.Geometry')),
                            React.createElement(Box, { layerStyle: 'options_4cols' },
                                React.createElement(Button, { variant: dataVarToUpdate.current.includes('posNode') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                        if (!dataVarToUpdate.current.includes('posNode')) {
                                            dataVarToUpdate.current.push('posNode');
                                            setForceUpdate(!forceUpdate);
                                        }
                                        else {
                                            dataVarToUpdate.current.splice(dataVarToUpdate.current.indexOf('posNode'), 1);
                                            setForceUpdate(!forceUpdate);
                                        }
                                    } }, t('Menu.Transformation.PosNoeud')),
                                React.createElement(Button, { variant: dataVarToUpdate.current.includes('posFlux') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                        if (!dataVarToUpdate.current.includes('posFlux')) {
                                            dataVarToUpdate.current.push('posFlux');
                                            setForceUpdate(!forceUpdate);
                                        }
                                        else {
                                            dataVarToUpdate.current.splice(dataVarToUpdate.current.indexOf('posFlux'), 1);
                                            setForceUpdate(!forceUpdate);
                                        }
                                    } },
                                    " ",
                                    t('Menu.Transformation.posFlux'))))),
                    mode_trans != 'simple' ?
                        React.createElement(OSTooltip, { label: t('Menu.Transformation.tooltips.Values') },
                            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.Values')),
                                React.createElement(Box, { layerStyle: 'options_4cols' },
                                    React.createElement(Button, { variant: dataVarToUpdate.current.includes('Values') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                            if (!dataVarToUpdate.current.includes('Values')) {
                                                dataVarToUpdate.current.push('Values');
                                                // Also need dataTags because we can't only import values without the structur of dataTags
                                                // (but we can import dataTags without values)
                                                if (!dataVarToUpdate.current.includes('tagData')) {
                                                    dataVarToUpdate.current.push('tagData');
                                                }
                                                setForceUpdate(!forceUpdate);
                                            }
                                            else {
                                                dataVarToUpdate.current.splice(dataVarToUpdate.current.indexOf('Values'), 1);
                                                setForceUpdate(!forceUpdate);
                                            }
                                        } }, dataVarToUpdate.current.includes('Values') ? React.createElement(FaCheck, null) : React.createElement(FontAwesomeIcon, { icon: faXmark }))))) : React.createElement(React.Fragment, null),
                    React.createElement(OSTooltip, { label: t('Menu.Transformation.tooltips.Attribut') },
                        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.Attribut')),
                            React.createElement(Box, { layerStyle: 'options_4cols' },
                                React.createElement(Button, { variant: dataVarToUpdate.current.includes('attrNode') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                        if (!dataVarToUpdate.current.includes('attrNode')) {
                                            dataVarToUpdate.current.push('attrNode');
                                            setForceUpdate(!forceUpdate);
                                        }
                                        else {
                                            dataVarToUpdate.current.splice(dataVarToUpdate.current.indexOf('attrNode'), 1);
                                            setForceUpdate(!forceUpdate);
                                        }
                                    } }, t('Menu.Transformation.attrNode')),
                                React.createElement(Button, { variant: dataVarToUpdate.current.includes('attrFlux') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                        if (!dataVarToUpdate.current.includes('attrFlux')) {
                                            dataVarToUpdate.current.push('attrFlux');
                                            setForceUpdate(!forceUpdate);
                                        }
                                        else {
                                            dataVarToUpdate.current.splice(dataVarToUpdate.current.indexOf('attrFlux'), 1);
                                            setForceUpdate(!forceUpdate);
                                        }
                                    } }, t('Menu.Transformation.attrFlux'))))),
                    mode_trans == 'expert' ?
                        React.createElement(OSTooltip, { label: t('Menu.Transformation.tooltips.Tags') },
                            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.Tags')),
                                React.createElement(Box, { layerStyle: 'options_4cols' },
                                    React.createElement(Button, { variant: dataVarToUpdate.current.includes('tagNode') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                            if (!dataVarToUpdate.current.includes('tagNode')) {
                                                dataVarToUpdate.current.push('tagNode');
                                                setForceUpdate(!forceUpdate);
                                            }
                                            else {
                                                dataVarToUpdate.current.splice(dataVarToUpdate.current.indexOf('tagNode'), 1);
                                                setForceUpdate(!forceUpdate);
                                            }
                                        } }, t('Menu.Transformation.tagNode')),
                                    React.createElement(Button, { variant: dataVarToUpdate.current.includes('tagFlux') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                            if (!dataVarToUpdate.current.includes('tagFlux')) {
                                                dataVarToUpdate.current.push('tagFlux');
                                                setForceUpdate(!forceUpdate);
                                            }
                                            else {
                                                dataVarToUpdate.current.splice(dataVarToUpdate.current.indexOf('tagFlux'), 1);
                                                setForceUpdate(!forceUpdate);
                                            }
                                        } }, t('Menu.Transformation.tagFlux')),
                                    React.createElement(Button, { variant: dataVarToUpdate.current.includes('tagData') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                            if (!dataVarToUpdate.current.includes('tagData')) {
                                                dataVarToUpdate.current.push('tagData');
                                                setForceUpdate(!forceUpdate);
                                            }
                                            else if (!dataVarToUpdate.current.includes('Values')) {
                                                dataVarToUpdate.current.splice(dataVarToUpdate.current.indexOf('tagData'), 1);
                                                setForceUpdate(!forceUpdate);
                                            }
                                        } }, t('Menu.Transformation.tagData'))))) : React.createElement(React.Fragment, null),
                    mode_trans == 'expert' ?
                        React.createElement(OSTooltip, { label: t('Menu.Transformation.tooltips.tagLevel') },
                            React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                                React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.tagLevel')),
                                React.createElement(Box, { layerStyle: 'options_4cols' },
                                    React.createElement(Button, { variant: dataVarToUpdate.current.includes('tagLevel') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                            if (!dataVarToUpdate.current.includes('tagLevel')) {
                                                dataVarToUpdate.current.push('tagLevel');
                                                setForceUpdate(!forceUpdate);
                                            }
                                            else {
                                                dataVarToUpdate.current.splice(dataVarToUpdate.current.indexOf('tagLevel'), 1);
                                                setForceUpdate(!forceUpdate);
                                            }
                                        } }, dataVarToUpdate.current.includes('tagLevel') ? React.createElement(FaCheck, null) : React.createElement(FontAwesomeIcon, { icon: faXmark }))))) : React.createElement(React.Fragment, null),
                    React.createElement(OSTooltip, { label: t('Menu.Transformation.tooltips.attrGeneral') },
                        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.Transformation.attrGeneral')),
                            React.createElement(Box, { layerStyle: 'options_4cols' },
                                React.createElement(Button, { variant: dataVarToUpdate.current.includes('attrGeneral') ? 'menuconfigpanel_option_button_activated' : 'menuconfigpanel_option_button', onClick: () => {
                                        if (!dataVarToUpdate.current.includes('attrGeneral')) {
                                            dataVarToUpdate.current.push('attrGeneral');
                                            setForceUpdate(!forceUpdate);
                                        }
                                        else {
                                            dataVarToUpdate.current.splice(dataVarToUpdate.current.indexOf('attrGeneral'), 1);
                                            setForceUpdate(!forceUpdate);
                                        }
                                    } }, dataVarToUpdate.current.includes('attrGeneral') ? React.createElement(FaCheck, null) : React.createElement(FontAwesomeIcon, { icon: faXmark }))))),
                    mode_trans == 'expert' ? apply_transformation_additional_elements.map((c, i) => {
                        return React.createElement(React.Fragment, { key: i }, c);
                    }) : React.createElement(React.Fragment, null))),
            React.createElement(TabPanel, null,
                React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
                    React.createElement("h5", null,
                        React.createElement("center", null, t('MEP.columnsParameters'))),
                    React.createElement(OSTooltip, { label: t('MEP.tooltips.EEN_h') },
                        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('MEP.Horizontal')),
                            React.createElement(NumberInput, { variant: 'menuconfigpanel_option_numberinput', step: 1, min: 0, allowMouseWheel: true, value: node_hspace, onChange: evt => {
                                    set_node_hspace(+evt);
                                    data.style_node['default'].dx = +evt;
                                } },
                                React.createElement(NumberInputField, null),
                                React.createElement(NumberInputStepper, null,
                                    React.createElement(NumberIncrementStepper, null),
                                    React.createElement(NumberDecrementStepper, null))))),
                    React.createElement(OSTooltip, { label: t('MEP.tooltips.EEN_v') },
                        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('MEP.Vertical')),
                            React.createElement(NumberInput, { variant: 'menuconfigpanel_option_numberinput', step: 1, min: 0, allowMouseWheel: true, value: node_vspace, onChange: evt => {
                                    set_node_vspace(+evt);
                                    data.style_node['default'].dy = +evt;
                                    if (data.style_node['NodeSectorStyle']) {
                                        data.style_node['NodeSectorStyle'].dy = +evt;
                                    }
                                    if (data.style_node['NodeProductStyle']) {
                                        data.style_node['NodeProductStyle'].dy = +evt;
                                    }
                                } },
                                React.createElement(NumberInputField, null),
                                React.createElement(NumberInputStepper, null,
                                    React.createElement(NumberIncrementStepper, null),
                                    React.createElement(NumberDecrementStepper, null)))))),
                React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
                    React.createElement("h5", null,
                        React.createElement("center", null, t('MEP.positioningMode'))),
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_4cols' },
                        React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: parametric, onChange: (evt) => {
                                set_parametric(evt.target.checked);
                                if (evt.target.checked) {
                                    Object.values(data.style_node)
                                        .filter(style => style.idNode !== 'NodeExportStyle' && style.idNode !== 'NodeImportStyle')
                                        .forEach(style => style.position = 'parametric');
                                    ComputeParametrization(applicationData);
                                }
                                else {
                                    Object.values(data.style_node)
                                        .filter(style => style.idNode !== 'NodeExportStyle' && style.idNode !== 'NodeImportStyle')
                                        .forEach(style => style.position = 'absolute');
                                }
                                applicationData.set_data(JSON.parse(JSON.stringify(data)));
                            } },
                            React.createElement(OSTooltip, { label: t('MEP.tooltips.parametricMode') }, t('MEP.parametricMode'))),
                        React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: !parametric, onChange: (evt) => {
                                set_parametric(!evt.target.checked);
                                if (!evt.target.checked) {
                                    Object.values(data.style_node)
                                        .filter(style => style.idNode !== 'NodeExportStyle' && style.idNode !== 'NodeImportStyle')
                                        .forEach(style => style.position = 'parametric');
                                    ComputeParametrization(applicationData);
                                }
                                else {
                                    Object.values(data.style_node)
                                        .filter(style => style.idNode !== 'NodeExportStyle' && style.idNode !== 'NodeImportStyle')
                                        .forEach(style => style.position = 'absolute');
                                }
                                applicationData.set_data(JSON.parse(JSON.stringify(data)));
                            } },
                            React.createElement(OSTooltip, { label: t('MEP.tooltips.absoluteMode') }, t('MEP.absoluteMode'))),
                        parametric ? React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                                if (parametric) {
                                    Object.values(applicationData.data.nodes)
                                        .filter(node => ReturnValueNode(data, node, 'position') !== 'relative')
                                        .forEach(node => { if (node.local)
                                        delete node.local.dy; });
                                }
                                applicationData.set_data(JSON.parse(JSON.stringify(data)));
                            } }, t('MEP.reInitDY')) : React.createElement(React.Fragment, null)),
                    React.createElement("hr", { style: { borderStyle: 'none', margin: '10px', color: 'grey', backgroundColor: 'grey', height: 2 } }),
                    React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
                        React.createElement("h5", null,
                            React.createElement("center", null, t('MEP.importExport'))),
                        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_3cols' },
                            React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: trade_close, onChange: (evt) => {
                                    set_trade_close(evt.target.checked);
                                    setTrade(evt.target.checked);
                                    if (parametric) {
                                        Object.values(applicationData.data.nodes)
                                            .filter(node => node.style === 'NodeExportStyle' || node.style === 'NodeImportStyle')
                                            .forEach(node => { if (node.local)
                                            delete node.local; });
                                        Object.values(applicationData.data.links)
                                            .filter(link => link.style === 'LinkExportStyle' || link.style === 'LinkImportStyle')
                                            .forEach(link => { if (link.local)
                                            delete link.local; });
                                    }
                                } },
                                React.createElement(OSTooltip, { label: t('MEP.tooltips.importExportClose') }, t('MEP.importExportClose'))),
                            React.createElement(Checkbox, { isDisabled: !parametric, variant: 'menuconfigpanel_option_checkbox', isChecked: !trade_close, onChange: (evt) => {
                                    set_trade_close(!evt.target.checked);
                                    setTrade(!evt.target.checked);
                                    if (parametric) {
                                        Object.values(applicationData.data.nodes)
                                            .filter(node => node.style === 'NodeExportStyle' || node.style === 'NodeImportStyle')
                                            .forEach(node => { if (node.local)
                                            delete node.local; });
                                        Object.values(applicationData.data.links)
                                            .filter(link => link.style === 'LinkExportStyle' || link.style === 'LinkImportStyle')
                                            .forEach(link => { if (link.local)
                                            delete link.local; });
                                    }
                                } },
                                React.createElement(OSTooltip, { label: t('MEP.tooltips.importExportAboveBelow') }, t('MEP.importExportAboveBelow'))))),
                    React.createElement("hr", { style: { borderStyle: 'none', margin: '10px', color: 'grey', backgroundColor: 'grey', height: 2 } }),
                    React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                        React.createElement(OSTooltip, { label: t('MEP.tooltips.PA') },
                            React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                                    applicationData.function_on_wait.current = () => {
                                        ComputeAutoSankey(applicationData, false);
                                        set_data(Object.assign({}, data));
                                    };
                                    dict_hook_ref_setter_show_dialog_components.ref_lauchToast.current();
                                } }, t('MEP.PA'))),
                        React.createElement(OSTooltip, { label: t('MEP.tooltips.AN') },
                            React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                                    arrangeNodes(data);
                                    set_data(Object.assign({}, data));
                                } }, t('MEP.AN'))))))));
    const dragLayout = MenuDraggable(dict_hook_ref_setter_show_dialog_components, 'ref_setter_show_apply_layout', content_modal_layout, { current: [window.innerWidth / 4, window.innerHeight / 4] }, t('Menu.Transformation.title'));
    return dragLayout;
};
/**
 *
 * @param {ApplySaveJSONTypes} { ref_setter_show_save_json, set_show_save_json,sankey_data,set_sankey_data,ClickSaveDiagram }
 * @returns {*}
 */
export const ApplySaveJSONDialog = ({ t, applicationData, dict_hook_ref_setter_show_dialog_components, applicationState, additional_file_save_json_option, ClickSaveDiagram }) => {
    const [mode_save, set_mode_save] = useState(true);
    const [mode_visible_element, set_mode_visible_element] = useState(false);
    const [show_save_json_modal, set_show_save_json_modal] = useState(false);
    dict_hook_ref_setter_show_dialog_components.ref_setter_show_save_json.current = set_show_save_json_modal;
    return React.createElement(Modal, { isOpen: show_save_json_modal, onClose: () => set_show_save_json_modal(false) },
        React.createElement(ModalContent, { maxWidth: 'inherit' },
            React.createElement(ModalHeader, null, t('Menu.SaveJSON')),
            React.createElement(ModalCloseButton, null),
            React.createElement(ModalBody, null,
                React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
                    React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: mode_save, onChange: () => set_mode_save(!mode_save) }, t('Menu.SaveValue')),
                    React.createElement(Checkbox, { variant: 'menuconfigpanel_option_checkbox', isChecked: mode_visible_element, onChange: () => set_mode_visible_element(!mode_visible_element) }, t('Menu.VisibleElement')),
                    additional_file_save_json_option.map(el => el))),
            React.createElement(ModalFooter, null,
                React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                    React.createElement("span", null),
                    React.createElement(Box, { layerStyle: 'options_2cols' },
                        React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                                ClickSaveDiagram(applicationData, applicationData.data, applicationState, {
                                    mode_save,
                                    mode_visible_element
                                });
                            } }, t('Menu.SaveJSON')),
                        React.createElement(Button, { variant: 'menuconfigpanel_del_button', onClick: () => {
                                set_show_save_json_modal(false);
                            } }, t('Menu.close')))))));
};
/**
 * Return the modal when we try to open an excel file
 *
 * @param {{ UploadExcelImpl: any; handleCloseDialog: any; set_data: any; data: any; set_show_excel_dialog: any; url_prefix: any; postProcessLoadExcel: any; launch: any; }} { UploadExcelImpl, handleCloseDialog, set_data, data, set_show_excel_dialog,url_prefix,postProcessLoadExcel,launch }
 * @returns
 */
export const ExcelModal = ({ t, UploadExcelImpl, url_prefix, launch, dict_hook_ref_setter_show_dialog_components, Reinitialization, pointer_pos }) => {
    const [input_file_name, set_input_file_name] = useState(undefined);
    const content = React.createElement(Box, { layerStyle: 'menuconfigpanel_grid' },
        React.createElement(Box, null,
            t('Menu.input_file_excel'),
            React.createElement(Input, { type: "file", accept: '.xlsx', onChange: (evt) => {
                    set_input_file_name(evt.target.files[0]);
                } })),
        React.createElement(Box, { layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Box, null),
            React.createElement(Button, { variant: "menuconfigpanel_option_button_secondary", onClick: () => {
                    Reinitialization();
                    launch(input_file_name.name);
                    UploadExcelImpl(dict_hook_ref_setter_show_dialog_components.ref_setter_show_excel_dialog.current, input_file_name, url_prefix);
                } }, t('Menu.ouvrir'))));
    return MenuDraggable(dict_hook_ref_setter_show_dialog_components, 'ref_setter_show_excel_dialog', content, pointer_pos, t('Menu.open_excel_file'));
};
export const OpenSankeyDiagramSelector = (t, convert_data, sankey_data, set_sankey_data, prev_sankey_data, set_prev_sankey_data, updateLayout, dataVarToUpdate, defaultData) => {
    const [file_layout, set_file_layout] = useState(undefined);
    return React.createElement(Box, null,
        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_part_title_2' }, t('Menu.Transformation.fmep')),
        React.createElement(Box, { layerStyle: 'menuconfigpanel_row_2cols' },
            React.createElement(Input, { type: "file", "aria-label": '', onChange: (evt) => set_file_layout(evt.target.files) }),
            React.createElement(Box, { layerStyle: 'options_2cols' },
                React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                        if (file_layout === undefined) {
                            return;
                        }
                        const reader = new FileReader();
                        reader.onload = (() => {
                            return ((e) => {
                                let result = e.target.result;
                                if (result) {
                                    result = String(result);
                                    const new_layout = JSON.parse(result);
                                    convert_data(new_layout, defaultData);
                                    complete_sankey_data(new_layout, defaultData, DefaultNode, DefaultLink);
                                    set_prev_sankey_data(JSON.parse(JSON.stringify(sankey_data)));
                                    updateLayout(sankey_data, new_layout, dataVarToUpdate.current, true);
                                    set_sankey_data(Object.assign({}, sankey_data));
                                }
                            });
                        })();
                        reader.readAsText(file_layout[0]);
                    } }, t('Menu.Transformation.ad')),
                React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                        set_sankey_data(JSON.parse(JSON.stringify(prev_sankey_data)));
                    } }, t('Menu.Transformation.undo')))));
};
// export const PopoverSelectorDetailNodes:FunctionComponent<popoverSelectorDetailNodesFType>=({
//   applicationContext,
//   applicationData,
//   applicationDraw,
//   node_function,
//   link_function
// }
// )=>{
//   const redrawAllNodes=()=>node_function.RedrawNodes(Object.values(applicationData.display_nodes))
//   const redrawAllLinks=()=>link_function.RedrawLinks(Object.values(applicationData.display_links))
//   return <Popover id='popover-details-level' style={{maxWidth:'100%'}}>
//     <Popover.Header as="h3">{applicationContext.t('Banner.ndd')}</Popover.Header>
//     <Popover.Body style={{  marginLeft: '5px', width: '350px' }}>
//       <>{(Object.entries(applicationData.data.levelTags).length > 0) ? (<>
//         {addSimpleLevelDropDown(
//           applicationData,applicationDraw.reDrawLegend,redrawAllNodes,redrawAllLinks,node_function.recomputeDisplayedElement
//         )}</>
//       ) : (<>
//         <Form.Control placeholder="Pas de filtrage" style={{ opacity: !windowSankey.SankeyToolsStatic ? '0.3' : '0', color: '#6c757d' }} disabled /></>)}</>
//     </Popover.Body>
//   </Popover>
// }
// export default PopoverSelectorDetailNodes
